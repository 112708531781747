import "../scss/base.scss";

require("./lib/modernizr");
require("./lib/detectizr");

require("what-input");

const jQuery = require("jquery");
window.$ = window.jQuery = jQuery;

let _debug = require("./lib/debug");

import "foundation-sites";
require("owl.carousel");

let NodesManagerFunctions = function () {
  this.bodyNode = $("body");
};

NodesManagerFunctions.prototype.init = function () {
  this.getNodes();
};

NodesManagerFunctions.prototype.getNodes = function () {
  let self = this;
  $("[data-function]")
    .add("[data\\:function]")
    .not(".hasBeenBound")
    .each(function () {
      let node = $(this),
        command = node.data("function"),
        options = node.data("options") || "";

      if (command != null) {
        let functions = command.split(" ");
        $.each(functions, function (i, v) {
          if (typeof self[v] == "function") {
            if (options != null && options != "") {
              if (typeof options == "string") {
                let fields = options.split(", ");
                if (typeof fields == "object" && fields != "") {
                  let data = new Function("return" + options);
                  options = data();
                } else {
                  options = "";
                }
              }

              self[v].apply(self, [node, options]);
            } else {
              self[v].apply(self, [node, options]);
            }
          } else {
            _debug.log("function " + v + " not found, called by ", node);
          }
        });
      } else {
        _debug.log("function " + command + " not found, called by ", node);
      }
      node.addClass("hasBeenBound");
    });
};

NodesManagerFunctions.prototype.cleanHeader = function (node) {

  let cleanHtml = {
    eNode: null,
    cover_element: null,
    is_deleted: false
  }

  cleanHtml.init = function (node) {
    this.eNode = node;
    this.cover_element = this.eNode.find('.bb-header__image');

    if ($(window).outerWidth() < 640 && !(this.is_deleted)) {
      this.removeImage();
    }

    this.checkWidth();
  };

  cleanHtml.checkWidth = function () {
    let _self = this;

    $(window).on('resize', function () {
      if ($(window).outerWidth() > 640 && _self.is_deleted) {
        _self.addImage();
      } else if ($(window).outerWidth() < 640 && !(_self.is_deleted)) {
        _self.removeImage();
      }
    })
  };

  cleanHtml.removeImage = function () {
    if (!(this.is_deleted)) {
      this.eNode.find('.bb-header__image').remove();
      this.is_deleted = true;
    }
  }
  cleanHtml.addImage = function () {
    if (this.is_deleted) {
      this.eNode.find('.bb-header__container').append(this.cover_element);
      this.is_deleted = false;
    }
  }

  cleanHtml.init(node);
}

NodesManagerFunctions.prototype.cleanHtml = function (node) {

  let cleanHtml = {
    eNode: null,
    cover_element: null,
    is_deleted: false
  }

  cleanHtml.init = function (node) {
    this.eNode = node;
    this.cover_element = this.eNode.find('.bb-cover__image');

    if ($(window).outerWidth() > 640 && !(this.is_deleted)) {
      this.removeImage();
    }

    this.checkWidth();
  };

  cleanHtml.checkWidth = function () {
    let _self = this;

    $(window).on('resize', function () {
      if ($(window).outerWidth() < 640 && _self.is_deleted) {
        _self.addImage();
      } else if ($(window).outerWidth() > 640 && !(_self.is_deleted)) {
        _self.removeImage();
      }
    })
  };

  cleanHtml.removeImage = function () {
    if (!(this.is_deleted)) {
      this.eNode.find('.bb-cover__image').remove();
      this.is_deleted = true;
    }
  }
  cleanHtml.addImage = function () {
    if (this.is_deleted) {
      this.eNode.find('.bb-container').prepend(this.cover_element);
      this.is_deleted = false;
    }
  }

  cleanHtml.init(node);
}

NodesManagerFunctions.prototype.sliderProduct = function (node) {

  node.find(".owl-carousel").owlCarousel({
    items: 1,
    autoplay: true,
    nav: true,
    loop: true,
    autoplayTimeout: 6000,
    autoplaySpeed: 800
  });
};

NodesManagerFunctions.prototype.sliderFeedback = function (node) {

  node.find(".owl-carousel").owlCarousel({
    items: 1,
    autoplay: true,
    loop: true,
    autoplayTimeout: 3000,
    autoplaySpeed: 200
  });
};

let Node = new NodesManagerFunctions();
Node.init();